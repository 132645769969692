
import { Component, Emit, Vue } from "vue-property-decorator";
import XDropdown from "../SimpleDropdown.vue";

@Component({
  components: { XDropdown },
})
class DownloadDocDropdown extends Vue {
  @Emit()
  emitUpdateDropdown(show: boolean) {
    this.$emit("update:show", show);
  }

  data() {
    const docTypes = [
      "Вертикальная таблица",
      "Матрица дата - горизонталь, час - вертикаль",
      "Матрица час - горизонталь, день - вертикаль",
    ];

    return { docTypes };
  }
}

export default DownloadDocDropdown;
