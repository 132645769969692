
import { Component, Prop, Vue } from "vue-property-decorator";
import ITableHeader from "@/models/table/Header.interface";
import IConsumptionByHour from "@/models/consumption-table-by-point/ConsumptionByHour.interface";
import XDataTable from "../hoc/Table.vue";
import { formatDate } from "@/lib/date";

@Component({
  components: { XDataTable },
  filters: {
    formatDate: (date: string): string => formatDate(date, "full"),
    formatHour: (hour: number): string =>
      hour > 9 ? `${hour}:00` : `0${hour}:00`,
  },
})
class ConsumptionTableByPoint extends Vue {
  [x: string]: any;

  @Prop({ required: true })
  readonly consumptionByHourList!: IConsumptionByHour[];

  data() {
    const headers: ITableHeader[] = [
      { text: "Дата", value: "date" },
      { text: "Час", value: "hour" },
      { text: "Объем", value: "value" },
    ];

    const requiredKeysByTd = ["дата", "час", "объем"];

    return { headers, requiredKeysByTd, totalConsumption: 0 };
  }

  public get preparedItems(): Array<string[]> {
    const { consumptionByHourList, requiredKeysByTd } = this;

    if (consumptionByHourList) {
      this.totalConsumption = 0;

      return consumptionByHourList.map((consumptionByHour) =>
        requiredKeysByTd.map((key: string) => {
          const tdValue = consumptionByHour[key];

          if (key === "объем") this.totalConsumption += tdValue;

          return tdValue;
        })
      );
    }

    return [];
  }
}

export default ConsumptionTableByPoint;
