var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('XDialog',{attrs:{"show":_vm.showDialog,"agreeButtonLoading":_vm.useAgreeButtonLoading,"agreeButtonState":!_vm.isValid},on:{"agree":function($event){return _vm.onAgree(_vm.$refs.fileInput.files[0], $event.name)},"disagree":function($event){return _vm.propagateCustomEvt($event.name)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Загрузить шаблон")]},proxy:true},{key:"default",fn:function(){return [_c('VForm',{staticClass:"form"},[_c('VRow',[_c('div',{class:[
            'form__field',
            'form__field_type_file',
            'mb-4',
            'pa-4',
            'rounded-lg',
          ]},[_c('input',{ref:"fileInput",staticClass:"form__input form__input_type_file",attrs:{"type":"file"},on:{"input":() => (_vm.chosenFileName = _vm.$refs.fileInput.files[0].name)}}),_c('span',{staticClass:"secondary--text"},[_vm._v("Перетащите файл сюда")]),_c('span',{staticClass:"secondary--text text--lighten-3 text-center"},[_vm._v("или")]),_c('XButton',{attrs:{"outlined":""},on:{"click":function($event){return _vm.$refs.fileInput.click()}}},[_vm._v(" Выберите файл ")])],1),_c('span',{staticClass:"form__text secondary--text"},[_vm._v(_vm._s(_vm.chosenFileName))])])],1)]},proxy:true},{key:"disagreeButton",fn:function(){return [_vm._v("Отмена")]},proxy:true},{key:"agreeButton",fn:function(){return [_vm._v("Загрузить")]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }